import React, { useEffect, useState } from 'react';
import DUOlogo from '../assets/whiteduologo.svg';
import { Navbar } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import duomobile from '../assets/duomobile-yellow.svg';
import duocard from '../assets/duocard.svg';
import cardnumber from '../assets/cardnumber.svg';
import transactionhistory from '../assets/transactionhistory.svg';
import AccessBank from '../assets/accessbank.png';
import Access from '../assets/access.svg';
import Platinum from '../assets/platinum.svg';
import home from '../assets/home-icon.svg';
import axiosCall from '../axios/axios';
import remap from '../assets/remap.svg';
import Loader from './Loader';






const VirtualCreditCard = ({ show, handleClose }) => {
   
    const [data1, setData1] = useState([]);
    const [expiryDate1, setExpiryDate1] = useState([]);
    const [customerName, setcustomerName] = useState([]);
    const [cardNumber1, setCardNumber1] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [lastAction, setLastAction] = useState(Date.now());
    const history = useHistory();
   
    useEffect(() => {
        const handleActivity = () => {
            setLastAction(Date.now());
        };

        const intervalId = setInterval(() => {
            const diff = Date.now() - lastAction;
            if (diff >= 60000) {

                history.push({
                    pathname: `/Home`,
                    state: { isactivity : true }
                });
               
            }
        }, 1000);

        document.addEventListener("keypress", handleActivity);
        document.addEventListener("keyup", handleActivity);
        document.addEventListener("keydown", handleActivity);
        document.addEventListener("click", handleActivity);

        return () => {
            clearInterval(intervalId);
            document.removeEventListener("keypress", handleActivity);
            document.removeEventListener("keyup", handleActivity);
            document.removeEventListener("keydown", handleActivity);
            document.removeEventListener("click", handleActivity);
        };
    }, [lastAction, history]);


    const [flip, setFlip] = useState(false);
    const handleClick = () => {
        setFlip(!flip);
    };


    useEffect(() => {
        (async () => {
          setIsLoading(true);
      
          const body = {
            "ACCOUNTNUMBER": localStorage.getItem("AccountNo")
          };
      
          try {
            const response = await axiosCall.POST('/customer/virtualcreditcard', body);
      
            if (response.data.status === "200" && response.data.message === "SUCCESS") {
                setIsLoading(false);
              setcustomerName([response.data.data.CUSTOMERNAME]);
             
              localStorage.setItem("cardnumber", response.data.data.CRDDATA);
              localStorage.setItem("cardvalue", response.data.data.CRDVERVALUE);
              localStorage.setItem("expirydate", response.data.data.EXPIRYDATE);
              localStorage.setItem("customerName", response.data.data.CUSTOMERNAME);
      
              // Create an array of promises for the last three API calls
              const promises = [         
                axiosCall.POST('/customer/identify', { "DECRYPT": localStorage.getItem("cardnumber") }), 
                axiosCall.POST('/customer/identify', { "DECRYPT": localStorage.getItem("cardvalue") }),  
                axiosCall.POST('/customer/identify', { "DECRYPT": localStorage.getItem("expirydate") }) 
             ];
      
              // Call Promise.all to execute all promises in parallel
              const results = await Promise.all(promises);
      
              // Update state with decrypted values
              setCardNumber1([results[0].data.data.OUTPUT]);
              setData1([results[1].data.data.OUTPUT]);
              setExpiryDate1([results[2].data.data.OUTPUT]);
            }
          } catch (error) {
            console.error(error);
            setIsLoading(false);
          }
      
         
        })();
      }, []);

    return (
        <React.Fragment>
            <div>
                <Navbar className="navbar navbar-expand-lg navbar-dark bg-dark" bg="dark" expand="lg">
                    <div className="navi">
                        <ul className='ulnavicon'>
                            <li className='linavigation imgduologo'> <img className="duosublogotopmain" src={DUOlogo} alt="logo" /></li>
                            <Link to="/Home">  <li className='linavigation'> <img className="duosublogotop " src={home} alt="logo" /></li> </Link>
                            <Link to="/VirtualCreditCard">  <li className='linavigation'> <img className="duosublogotop " src={duomobile} alt="logo" /></li> </Link>
                            <Link to="/PinVerification?page=TransactionHistory">   <li className='linavigation'> <img className="duosublogotop " src={transactionhistory} alt="logo" /></li> </Link>
                            <Link to="/PinVerification?page=RepaymentRequest">  <li className='linavigation'> <img className="duosublogotop " src={duocard} alt="logo" /></li></Link>
                            <Link to="/PinVerification?page=Remap">  <li className='linavigation remaplogo'> <img className="duosublogotop " src={remap} alt="logo" /></li></Link>
                            {/* <Link to="/PinVerification?page=ApplyDuoCard">  <li className='linavigation'> <img className="duosublogotop " src={cardnumber} alt="logo" /></li></Link> */}

                        </ul>
                    </div>
                </Navbar>
                <p className='homesummery'>Virtual Credit Card</p>
                {isLoading ? <div>
                    <div className="container-listitem ">
                        {(() => {
                            if (localStorage.getItem("CardIssuer") === "ACCESS BANK PLC") {
                                return (
                                    <div className="listitemrepayment">
                                        <img className="duologohome icontype" src={Access} alt="logo" />
                                        <p className="accessicon"> {localStorage.getItem("CardIssuer")}</p>
                                    </div>
                                )
                            }

                        })()}
                        {(() => {
                            if (localStorage.getItem("respcode") === "70") {
                                return (
                                    <div className="listitemrepayment1">
                                        <div className='statuscircle2'></div>
                                        <p className="activationsStatus"> Activations Status *</p>
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div className="listitemrepayment1">
                                        <div className='statuscircle1'></div>
                                        <p> Activations Status *</p>
                                    </div>
                                )
                            }
                        })()}

                        {(() => {
                            if (localStorage.getItem("CardType") === "PLATINUM") {
                                return (
                                    <div className="listitemrepayment2">
                                        <img className="duologohome" src={Platinum} alt="logo" />
                                        <p className="accessicon1">{localStorage.getItem("CardType")}</p>
                                    </div>
                                )
                            }
                        })()}
                    </div>

                    <hr className='hrline' />
                    
                    <div className='container containervirtual'>
                        <div className='row'>
                            <div className='col-md-3'>
                            </div>
                            <div className='col-md-6'>

                                <div className=''>
                                    {(() => {
                                        if (localStorage.getItem("respcode") === "70" || localStorage.getItem("respcode") === "71") {
                                            return (
                                                <div className='registerVirtual'>
                                                    <p className='virtualtext'> “Green” status means that you have been Activated and therefore you can start making DTS transaction requests now
                                                    </p>
                                                </div>
                                            )
                                        }
                                        else {
                                            return (
                                                <div className='registerVirtual'>
                                                    <p className='virtualtext'> * “Orange” status means that your Activation Request is still being attended to. This may take up to 24 hours time before you start making transaction requests
                                                    </p>
                                                </div>
                                            )

                                        }
                                    })()}
                                     
                                    <div className='cardimageshow'>
                                    <Loader className="loadertext" />
                                        <div onClick={handleClick} className="poster-container">
                                            <div className={flip ? "poster flipped" : "poster"}>
                                                <div className='pic'>
                                                    <div className='boxcard'>
                                                        <div className='boxtoplogo'>
                                                            <div className='logocard'>
                                                                <img className="duologohomev" src={DUOlogo} alt="logo" />
                                                            </div>
                                                            <div className='righticon'>
                                                                <img className="duologohome" src={AccessBank} alt="logo" />
                                                            </div>
                                                        </div>
                                                        <div className='boxtext'>
                                                            <div className='boxtext1'>
                                                                <p className='cardnumber'>{String(cardNumber1).replace(/(\d{4})/g, "$1 ")}</p>
                                                                <div className='cardinformation'>
                                                                    <p className='valid'>Valid <br />Thru </p>
                                                                    <p className='month'>Month/Year <br />{expiryDate1}</p>
                                                                </div>
                                                            </div>
                                                            <div className='cardname'>
                                                                <p className='namecards'></p>
                                                                <div className='righticon bottomicon'>
                                                                    <img className="duologohome" src={Platinum} alt="logo" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className='cvvtextdetails'>Tap on card to see the CVV</p>
                                                </div>

                                                <div className='pic back'>
                                                    <div className='boxcard1'>
                                                        <div className='cvvblack'></div>
                                                        <div className='cvvwhite'><p className='cvvnum'>{data1}</p></div>
                                                    </div>
                                                    <p className='cvvtextdetails'>Tap on card to see the Card Details</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className='col-md-3'>
                            </div>
                        </div>
                    </div>
                </div> :
                    <div>
                       <div className="container-listitem ">
                        {(() => {
                            if (localStorage.getItem("CardIssuer") === "ACCESS BANK PLC") {
                                return (
                                    <div className="listitemrepayment">
                                        <img className="duologohome icontype" src={Access} alt="logo" />
                                        <p className="accessicon"> {localStorage.getItem("CardIssuer")}</p>
                                    </div>
                                )
                            }

                        })()}
                        {(() => {
                            if (localStorage.getItem("respcode") === "70" || localStorage.getItem("respcode") === "71") {
                                return (
                                    <div className="listitemrepayment1">
                                        <div className='statuscircle2'></div>
                                        <p className="activationsStatus"> Activations Status *</p>
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div className="listitemrepayment1">
                                        <div className='statuscircle1'></div>
                                        <p> Activations Status *</p>
                                    </div>
                                )
                            }
                        })()}

                        {(() => {
                            if (localStorage.getItem("CardType") === "PLATINUM") {
                                return (
                                    <div className="listitemrepayment2">
                                        <img className="duologohome" src={Platinum} alt="logo" />
                                        <p className="accessicon1">{localStorage.getItem("CardType")}</p>
                                    </div>
                                )
                            }
                        })()}
                    </div>

                        <hr className='hrline' />
                        <div className='container containervirtual'>
                            <div className='row'>
                                <div className='col-md-3'>
                                </div>
                                <div className='col-md-6'>

                                    <div className=''>
                                        {(() => {
                                            if (localStorage.getItem("respcode") === "70" || localStorage.getItem("respcode") === "71") {
                                                return (
                                                    <div className='registerVirtual'>
                                                        <p className='virtualtext'> “Green” status means that you have been Activated and therefore you can start making DTS transaction requests now
                                                        </p>
                                                    </div>
                                                )
                                            }
                                            else {
                                                return (
                                                    <div className='registerVirtual'>
                                                        <p className='virtualtext'> * “Orange” status means that your Activation Request is still being attended to. This may take up to 24 hours time before you start making transaction requests
                                                        </p>
                                                    </div>
                                                )

                                            }
                                        })()}
                                        <div className='cardimageshow'>
                                            <div onClick={handleClick} className="poster-container">
                                                <div className={flip ? "poster flipped" : "poster"}>
                                                    <div className='pic'>
                                                        <div className='boxcard'>
                                                            <div className='boxtoplogo'>
                                                                <div className='logocard'>
                                                                    <img className="duologohomev" src={DUOlogo} alt="logo" />
                                                                </div>
                                                                <div className='righticon'>
                                                                    <img className="duologohome" src={AccessBank} alt="logo" />
                                                                </div>
                                                            </div>
                                                            <div className='boxtext'>
                                                                <div className='boxtext1'>
                                                                    <p className='cardnumber'>{String(cardNumber1).replace(/(\d{4})/g, "$1 ")}</p>
                                                                    <div className='cardinformation'>
                                                                        <p className='valid'>Valid <br />Thru </p>
                                                                        <p className='month'>Month/Year <br />{expiryDate1}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='cardname'>
                                                                    <p className='namecards'>{customerName}</p>
                                                                    <div className='righticon bottomicon'>
                                                                        <img className="duologohome" src={Platinum} alt="logo" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className='cvvtextdetails'>Tap on card to see the CVV</p>
                                                    </div>

                                                    <div className='pic back'>
                                                        <div className='boxcard1'>
                                                            <div className='cvvblack'></div>
                                                            <div className='cvvwhite'><p className='cvvnum'>{data1}</p></div>
                                                        </div>
                                                        <p className='cvvtextdetails'>Tap on card to see the Card Details</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className='col-md-3'>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    )

}
export default VirtualCreditCard;