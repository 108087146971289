import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';



const Chart = (props) => {
  const [chartData, setChartData] = useState({
    series:[0],
    
  });
  const [total, setTotal] = useState(0);
  const [remainingBalanace , setremainingBalance] = useState(0);
  const [series, setSeries] = useState([]);
  const [chartValueColor, setChartValueColor] = useState('#000');
  const [availableBalance, setAvailableBalance] = useState(0);
  const [totallimit, setTotallimit] = useState(0);




  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setChartValueColor('#fff');
    } else {
      setChartValueColor('#000');
    }
  }, [chartValueColor]);


  useEffect(() => {
    if (props !== undefined && props.data !== undefined && props.data.AVAILABLE_BALANCE !== undefined && props.data.ACTUAL_LIMIT !== undefined) {
      setremainingBalance(props.data.AVAILABLE_BALANCE);
      setTotal(props.data.ACTUAL_LIMIT);
      setTotallimit()
    }
    
  }, [props]);


  useEffect(() => {
    const spent = total-remainingBalanace;
    // setChartData({
    //   series:[spent]
    // })
    const availableCreditPercentage = (((total - Math.abs(spent)) / total) * 100);
    setSeries([availableCreditPercentage]);
    // setAvailableBalance(localStorage.getItem("AvailableBalance"));
    console.log("aaa", total );
    setOptions({
      plotOptions: {
        radialBar: {

          dataLabels: {
            value: {
              max: {total},
              formatter: function (val) {
                  
                // return '₦ ' + (total - Math.abs(chartData)).toLocaleString("en-US", { minimumFractionDigits: 2 });

                return '₦ ' +  Math.abs(remainingBalanace).toLocaleString("en-US", { minimumFractionDigits: 2 });
            },
           
            }
          },

        }
      }
    });


  }, [total, chartData]);



  const [options, setOptions] = useState({
    chart: {
      height: 300,
      type: 'radialBar',
      toolbar: {
        show: false
      }
    },

    colors: ["#B5D339"],
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        hollow: {
          margin: 0,
          size: '70%',
          background: 'transparent',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'back',
        },
        track: {
          background: '#FB8735',
          startAngle: -135,
          endAngle: 135,
        },

        grid: {

          padding: {

            top: 0,

          },
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: 10,
            show: true,
            color: chartValueColor, // updated color property
            fontSize: '16px',
            fontFamily: 'Roboto Condensed, sans-serif',
            style: {
              fontFamily: 'Roboto Condensed, sans-serif'
            },
          },

          value: {
            max: { total },
            formatter: function (val) {
                  
              // return '₦ ' + (total - Math.abs(chartData)).toLocaleString("en-US", { minimumFractionDigits: 2 });
              return '₦ ' +  Math.abs(remainingBalanace).toLocaleString("en-US", { minimumFractionDigits: 2 });
          },
        
            color: chartValueColor, // updated color property
            fontSize: '24px',
            show: true,
            offsetY: -30,
          }
        },

      }

    },
    style: {
      fontFamily: 'Roboto Condensed'
    },

    fill: {
      gradient: {
        shade: "dark",
        type: "horizontal",
        gradientToColors: ["#87D4F9"],
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: 'butt'
    },

    labels:  ["Available Credit"],
  });

  return (
    <div id="card" style={{ marginTop: '-30px' }}>

      <div id="chart">
        <ReactApexChart className="reactcharts" options={options} series={series} type="radialBar" height={350}
        />
      </div>

    </div>
  );
};

export default Chart;