import React, { useState } from 'react';
import Modal from 'react-modal';

const Select = ({ options, selectedOption, onOptionSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  
  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setSearchQuery('');
  };

  const handleOptionSelect = (option) => {
    onOptionSelect(option);
    handleCloseModal();
  };

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <div className='text-input'>
      <textarea type="text" id="myTextarea"
        
         rows="2"
         style={{ overflow: "hidden" }}
        
       placeholder="Please select your Preferred branch"
         className='searchinputselect'
          value={selectedOption} 
          onClick={handleOpenModal} />
      </div>
      <Modal isOpen={isOpen} onRequestClose={handleCloseModal}>
        <div className='selectbranch'>
            <p className="selectpreferred">Please select your Preferred branch</p>
          <input
            type="text"
            className='searchinput'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Please select your Preferred branch"
          />
          <ul className='listbranch'>
            {filteredOptions.map((option) => (
              <li key={option} onClick={() => handleOptionSelect(option)}>
                {option}
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </div>
  );
};

export default Select;