import React from 'react';
import DUOlogo from '../assets/whiteduologo.svg';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import duomobile from '../assets/duomobile.svg';
import duocard from '../assets/duocard.svg';
import cardnumber from '../assets/cardnumber-yellow.svg';
import transactionhistory from '../assets/transactionhistory.svg';
import home from '../assets/home-icon.svg';




const ApplyDuoCard3 = () => {


    return (
        <React.Fragment>
           <Navbar className="navbar navbar-expand-lg navbar-dark bg-dark" bg="dark" expand="lg">
                <div className="navi">
                <ul className='ulnavicon'>
                        <li className='linavigation imgduologo'> <img className="duosublogotop " src={DUOlogo} alt="logo" /></li>
                        <Link to="/Home">  <li className='linavigation'> <img className="duosublogotop " src={home} alt="logo" /></li> </Link>
                        <Link to="/VirtualCreditCard">  <li className='linavigation'> <img className="duosublogotop " src={duomobile} alt="logo" /></li> </Link>
                         <Link to="/PinVerification?page=TransactionHistory">   <li className='linavigation'> <img className="duosublogotop " src={transactionhistory} alt="logo" /></li> </Link>
                         <Link to="/PinVerification?page=RepaymentRequest">  <li className='linavigation'> <img className="duosublogotop " src={duocard} alt="logo" /></li></Link>
                         {/* <Link to="/PinVerification?page=ApplyDuoCard">  <li className='linavigation'> <img className="duosublogotop " src={cardnumber} alt="logo" /></li></Link>
                        */}
                    </ul>
                </div>
            </Navbar>
            <p className='homesummery'>Apply for DUO Card</p>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                    </div>
                    <div className='col-md-6'>
                        <p className="applytext">Your Branch details</p>
                        <div>
                            <p className='nairaduocard'>Access Bank Branch Oyin Jolayemi</p>
                        </div>
                        <div className=''>
                            <p className="remapdebit">Submit your request to upgrade to Duo Card Product. You would be notified on this App when your
                                card is ready for pick-up from the above branch</p>
                            <p className="remapdebit">In case you want to pick up your Duo Card from a different branch, please select the option given
                                below and let us know the branch you would like us to deliver this card</p>
                        </div>
                        <form className='formapply'>
                            <div>
                                <input type="radio" value="terms" />
                                <span className='branchradio'>I want to pick-up this card from another branch</span> </div>
                            <div className="form-group">
                                <select className="form-control selectduo" id="exampleFormControlSelect1">
                                    <option>Please Select your Preferred branch</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                            <div className='register-button'>
                                <Link to="/Pin"><button className='nextbutton'>SUBMIT</button></Link>
                            </div>
                        </form>
                    </div>
                    <div className='col-md-3'>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}
export default ApplyDuoCard3;