import React from 'react';
import DUOlogo from '../assets/whiteduologo.svg';
import { Link } from 'react-router-dom';



const PinVerification = (props) => {
 
    return (
        <React.Fragment>
            <div>

                <div className='HeaderBgverify'>
                    <div className="duoheaderveryfi">
                        <img className="duolog" src={DUOlogo} alt="logo" />
                    </div>
                    <p className='pinverifyheader'> PIN Set-up</p>
                </div>

            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                    </div>
                    <div className='col-md-6'>

                        <div className='resendpinotp'>
                           <p className='resend-congs'>Congratulations !!</p>
                           <p className='resend-text'>Your PIN for AccessDuo app has been successfully updated.</p>
                           <br />
                           <div className='register-button'>
                             <Link to="/Home"><button className="btn btn-warning nextbuttonpinreg">Next </button></Link> 
                                </div>
                        </div>


                    </div>
                    <div className='col-md-3'>
                    </div>
                </div>
            </div>


        </React.Fragment>
    )

}
export default PinVerification;