import axios from 'axios';

const baseUrl='https://duoapi.duocard.org';


// const baseUrl = 'https://duoapiqa.duocard.org';

const contentJSON = {
    'content-type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    'source': 'webview',
    'clientcode': '0001' ,
    'customercode': 'abcd',
    'clientcodeencr': 'sdfsdfsdf'
};

const axiosBranch = {

    GET: async function (url, header) {
        const headers = header;
        let answer = await axios.get(baseUrl + url, { headers: headers });
        console.log(answer);
        return answer;
    },

    POST: async function (url, bodyData, header) {
        contentJSON['webviewauthorization'] = localStorage.getItem("webviewauthorization");
        contentJSON['webviewuserid'] = localStorage.getItem("webviewuserid");
        // contentJSON['customerid'] = localStorage.getItem("customerid");
        // contentJSON['bankcode'] = localStorage.getItem("bankcode");
        console.log("contenss", contentJSON)
        const headers = contentJSON;
        const bodydata = bodyData;
        return await axios.post(baseUrl + url, bodydata, { headers: headers });
    },

    PUT: async function (url, body) {
        const config = { headers: contentJSON };
        return await axios.put(baseUrl + url, body, config);

    }
}

export default axiosBranch;