import React, { useState, useEffect } from 'react';
import DUOlogo from '../assets/whiteduologo.svg';
import OtpInput from 'react18-input-otp';
import { useHistory, Redirect } from 'react-router-dom';
import axiosCall from '../axios/axios';
import { Modal, Button } from 'react-bootstrap';

const ResendPin = (props) => {
    const [PIN, setPIN] = useState('');
    const [error, setError] = useState('');
    const [isSendingOTP, setIsSendingOTP] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);
    const [success, setSuccess] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [errormsg, setErrormsg] = useState('');


    let history = useHistory();
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (PIN.length !== 6 || isNaN(PIN)) {
            setError('Please enter 6 digit OTP');
        } else {
            const body = {
                "ACCOUNTNUMBER": localStorage.getItem("AccountNo"),
                "TOKEN": "5599",
                "OTP": PIN
            }
            axiosCall.POST('/registration/mobileotpverify', body)
                .then((response) => {
                    if (response.data.status === "200" && response.data.data.validOtp === true) {
                        setSuccess(true);
                    } else {
                        setErrormsg(response.data.data.MESSAGE);
                        setModalOpen(true);
                    }
                }).catch(err => {
                    console.log(err)
                    setModalOpen(true);
                    setErrormsg("Please try After Sometime");
                });
        }
    }

    const handleOTPChange = (pin) => {
        setPIN(pin);
        setError('');
    };

    const handleResendOTP = () => {
        const body = {
            "ACCOUNTNUMBER": localStorage.getItem("AccountNo"),
            "TOKEN": "5599",
            "CUSTOMEREMAIL": localStorage.getItem("email"),
            "DLINK_DEVICE": "NO"
        }
        axiosCall.POST('/registration/mobileotp', body)
            .then((response) => {
                if (response.data.status === "200" && response.data.data.validOtp === true) {
                    setIsSendingOTP(true);
                } else {
                    setErrormsg(response.data.data.MESSAGE);
                    setModalOpen(true);
                }
            }).catch((error) => {
                console.log(error);

            });
    };

    useEffect(() => {
        if (isSendingOTP) {
            setRemainingTime(120);
            setTimeout(() => {
                console.log()
                setIsSendingOTP(false);
            }, 0);
        }
    }, [isSendingOTP]);

    useEffect(() => {
        if (remainingTime > 0) {
            const timer = setInterval(() => {
                setRemainingTime(prevTime => prevTime - 1);
            }, 2000);
            return () => clearInterval(timer);
        }
    }, [remainingTime]);

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleRedirect = () => {
        handleCloseModal();
        history.push('/Home');
    };



    if (success) {
        return (
            <Redirect
                to={{
                    pathname: "/ForgotPinSetup",
                    state: {}
                }}>
            </Redirect>
        )

    } else {

        return (
            <React.Fragment>
                <div>

                    <div className='HeaderBgverify'>
                        <div className="duoheaderveryfi">
                            <img className="duolog" src={DUOlogo} alt="logo" />
                        </div>
                        <p className='pinverifyheader'> PIN Verfication</p>
                    </div>

                </div>

                <div>
                    {modalOpen && (
                        <Modal show={modalOpen} handleClose={handleCloseModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Alert</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className='modaltext'>{errormsg}</p>
                                <hr className='hrline' />
                            </Modal.Body>
                            <Modal.Footer onClick={handleRedirect} >
                                <Button variant="secondary" >
                                    OK
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    )}
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3'>
                        </div>
                        <div className='col-md-6'>

                            <div className='formfirst pinform'>
                                <form onSubmit={handleSubmit}>
                                    <div >
                                        <label className="uploadlabel">Please Enter your OTP</label>
                                        <div className='pinInput'>
                                            <div className='otpfields'>
                                                <OtpInput
                                                    inputStyle='otpinputs'
                                                    value={PIN}
                                                    autoFocus
                                                    onChange={handleOTPChange}
                                                    numInputs={6}
                                                    isInputNum="number"
                                                    inputProps={{ inputMode: "numeric" }}
                                                    disabled={false}
                                                />
                                            </div>
                                            {/* <OTPInput
                                            className='otpinputs'
                                            value={PIN}
                                            onChange={handleOTPChange}
                                            autoFocus
                                            OTPLength={6}
                                            otpType='number'
                                            disabled={false}
                                        /> */}
                                        </div>
                                        {error && <div className='pinerror'>{error}</div>}

                                    </div>

                                    {remainingTime > 0 ? (
                                        <div className='resend' >
                                            <button className="btn resendOtp" disabled > Resend OTP</button>
                                            <span className='remaining timer'> ({remainingTime}s)</span>
                                            {/* Resend OTP ({remainingTime}s) */}
                                        </div>
                                    ) : (
                                        <div className=''>
                                            <div className='resend'>
                                                <button className="btn resendOtp" onClick={handleResendOTP}> Resend OTP</button>
                                            </div>
                                        </div>
                                    )}
                                    <div className='register-button'>
                                        <input type="submit" className="btn btn-warning nextbuttonpin" value="Next" />
                                    </div>
                                </form>
                            </div>


                        </div>
                        <div className='col-md-3'>
                        </div>
                    </div>
                </div>




            </React.Fragment>
        )
    }
}
export default ResendPin;