import React from 'react';
import Access from '../assets/access.svg';
import Platinum from '../assets/platinum.svg';


const CardtData = (props) => {


    return (
        <React.Fragment>
            <div className="container-listitem ">

                {(() => {
                    if (localStorage.getItem("CardIssuer") === "ACCESS BANK PLC") {
                        return (
                            <div className="listitemrepayment cardtypelist">
                                <img className="duologohome icontype" src={Access} alt="logo" />
                                <p className="accessicon"> {localStorage.getItem("CardIssuer")}</p>
                            </div>
                        )
                    }

                })()}

                <div className="listitemrepayment1 cardtypelist">
                </div>
                {(() => {
                    if (localStorage.getItem("CardType") === "PLATINUM") {
                        return (
                            <div className="listitemrepayment2 cardtypelist">
                                <img className="duologohome" src={Platinum} alt="logo" />
                                <p className="accessicon1">{localStorage.getItem("CardType")}</p>
                            </div>
                        )
                    }
                })()}

            </div>
        </React.Fragment>
    )
}

export default CardtData;