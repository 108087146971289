import React from 'react';
import DUOlogo from './assets/whiteduologo.svg';



const Header = () => {


    return (
        <React.Fragment>
            <div className='HeaderBg'>
                <div className="duoheader">
                <img className="duolog" src={DUOlogo} alt="logo" />
            </div>
            </div>
        </React.Fragment>
    )

}
export default Header;