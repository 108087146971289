import React, { useEffect, useState } from 'react';
import DUOlogo from '../assets/whiteduologo.svg';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import duomobile from '../assets/duomobile.svg';
import duocard from '../assets/duocard.svg';
import cardnumber from '../assets/cardnumber-yellow.svg';
import transactionhistory from '../assets/transactionhistory.svg';
import home from '../assets/home-icon.svg';
import { useHistory, Redirect } from 'react-router-dom';
import axiosCall from '../axios/axios';
import remap from '../assets/remap.svg';
import { Modal, Button } from 'react-bootstrap';
import Loader from './Loader';


const ApplyDuoCard = (props) => {
    const [checked, setChecked] = useState("NO");
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [branchAdd, setBranchAdd] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [errormsg, setErrormsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleCheckboxChange = (event) => {
        const newValue = event.target.checked ? 'YES' : 'NO';
        setChecked(newValue);
        setError('');
    };

    let history = useHistory();
    
    const handleSubmit = async (event) => {
        event.preventDefault();
       
        if (checked === "NO") {
            setError('Please confirm do you want to upgrade Duo Card experience');
        } else {
            // setSuccess(true);
            setIsLoading(true);
            const body = {
                "ACCOUNTNUMBER": localStorage.getItem("AccountNo"),
                "BRANCHADDRESS": "",
                "ACCEPTTERMSANDCONDITION": checked
            }
            await axiosCall.POST('/customer/upgradedualcard', body)
                .then((response) => {
                    if (response.data.status === "200" && response.data.data.RESPCODE === "0" ) {
                        localStorage.setItem("branch", response.data.data.BRANCH_NAME);
                        setSuccess(true);
                        setIsLoading(false);
                    }
                    else {
                        setErrormsg(response.data.data.MESSAGE);
                        setModalOpen(true);
                        setIsLoading(false);
                    }
                }).catch((error) => {
                    console.log(error);
                    setModalOpen(true);
                    setErrormsg("Please try After Sometime");
                    setIsLoading(false);
                });
        }
    };
    useEffect(() => {
        (async () => {

            const body = {
            }
            await axiosCall.POST('/customer/allbranch', body)
                .then((response) => {
                    if (response.data.status === "200" && response.data.message === "SUCCESS") {
                        const indexArray = response.data.data.map((item, index) =>
                            item.ADDRESS
                        );
                        setBranchAdd(indexArray)
                    }
                }).catch(err => {
                    console.error(err);
                });
        })();
    }, [])

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleRedirect = () => {
        handleCloseModal();
        history.push('/Home');
    };

    if (success) {
        return (
            <Redirect
                to={{
                    pathname: "/ApplyDuoCard1",
                    state: { indexArray: branchAdd }
                }}>
            </Redirect>
        )

    } else {
        return (
            <React.Fragment>
                <Navbar className="navbar navbar-expand-lg navbar-dark bg-dark" bg="dark" expand="lg">
                    <div className="navi">

                        <ul className='ulnavicon'>
                            <li className='linavigation imgduologo'> <img className="duosublogotopmain" src={DUOlogo} alt="logo" /></li>
                            <Link to="/Home">  <li className='linavigation'> <img className="duosublogotop " src={home} alt="logo" /></li> </Link>
                            <Link to="/PinVerification?page=VirtualCreditCard">  <li className='linavigation'> <img className="duosublogotop " src={duomobile} alt="logo" /></li> </Link>
                            <Link to="/PinVerification?page=TransactionHistory">   <li className='linavigation'> <img className="duosublogotop " src={transactionhistory} alt="logo" /></li> </Link>
                            <Link to="/PinVerification?page=RepaymentRequest">  <li className='linavigation'> <img className="duosublogotop " src={duocard} alt="logo" /></li></Link>
                            <Link to="/PinVerification?page=Remap">  <li className='linavigation remaplogo'> <img className="duosublogotop " src={remap} alt="logo" /></li></Link>
                            <Link to="/ApplyDuoCard">  <li className='linavigation'> <img className="duosublogotop " src={cardnumber} alt="logo" /></li></Link>

                        </ul>
                    </div>
                </Navbar>
                <p className='homesummery'>Apply for DUO Card</p>
                <div>
                    {modalOpen && (
                        <Modal show={modalOpen} handleClose={handleCloseModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Alert</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className='modaltext'>{errormsg}</p>
                                <hr className='hrline' />
                            </Modal.Body>
                            <Modal.Footer onClick={handleRedirect}>
                                <Button variant="secondary" >
                                    OK
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    )}
                </div>
                {isLoading ? <div>
                    <Loader className="loadertext" />
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3'>
                        </div>
                        <div className='col-md-6'>
                            <form >
                                <div className='applyduotext'>
                                    <label className="custom-checkbox">
                                        <input type="checkbox" checked={checked === 'YES'} onChange={handleCheckboxChange} />
                                        <span className="checkmark"></span>
                                        <span className="checkbox-label">I want to upgrade to Duo Card experience. Please issue me a Dual Chip Card which I am willing to collect from my Bank Branch.
                                        </span>
                                    </label>

                                </div>
                                {error && <div className='pinerror termserror'>{error}</div>}
                                <br />
                                <div className='register-button'>
                                    <input type="submit" className="btn btn-warning nextbuttonpin" value="Next" />
                                </div>
                            </form>
                        </div>
                        <div className='col-md-3'>
                        </div>
                    </div>
                </div> 
                </div> :
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3'>
                        </div>
                        <div className='col-md-6'>
                            <form onSubmit={handleSubmit}>
                                <div className='applyduotext'>
                                    <label className="custom-checkbox">
                                        <input type="checkbox" checked={checked === 'YES'} onChange={handleCheckboxChange} />
                                        <span className="checkmark"></span>
                                        <span className="checkbox-label">I want to upgrade to Duo Card experience. Please issue me a Dual Chip Card which I am willing to collect from my Bank Branch.
                                        </span>
                                    </label>

                                </div>
                                {error && <div className='pinerror termserror'>{error}</div>}
                                <br />
                                <div className='register-button'>
                                    <input type="submit" className="btn btn-warning nextbuttonpin" value="Next" />
                                </div>
                            </form>
                        </div>
                        <div className='col-md-3'>
                        </div>
                    </div>
                </div>
                }
            </React.Fragment>
        )
    }
}
export default ApplyDuoCard;