
import React, { useEffect, useState } from 'react';
import axiosBranch from '../axios/axiosBranch';
// import RegistrationPage1 from './RegistrationPage1';
// import RegistrationPage2 from './RegistrationPage2';
// import RegistrationPage4 from './RegistrationPage4';
// import RegistrationPage from './RegistrationPage';
// import Home from './Home';
import {useHistory } from 'react-router-dom';

const AppHome = () => {
  const [arrayData, setArrayData] = useState({});

  const history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);

  const webviewauthorization = queryParams.get("webviewauthorization");
  const webviewuserid = queryParams.get("webviewuserid");
  const customerid = queryParams.get("customerid");
  const bankcode = queryParams.get("bankcode");
   localStorage.setItem("webviewauthorization", webviewauthorization);
  localStorage.setItem("webviewuserid", webviewuserid);
  localStorage.setItem("customerid", customerid);
  localStorage.setItem("bankcode", bankcode);




  console.log("hello", localStorage.getItem("customerid"))
  useEffect(() => {
    (async () => {
      const body = {
        "CUSTOMERID": customerid,
        "BANKCODE": "044",
      }
      await axiosBranch.POST('/webview/checkcustomerid', body)
        .then((response) => {
          if (response.data.status === "200" && response.data.message === "SUCCESS") {
        
            const data = response?.data?.data?.daoInfo?.[0] ?? {}
               
            const respCode = data?.RESPCODE ?? '';
            const isDuoUser = data?.duoUser;
            
            if (respCode === "74" && isDuoUser) {
              history.push("/RegistrationPage2");
            }  
            if (respCode === "74" && !isDuoUser) {
              history.push("/RegistrationPage4");
            } 
            if (["70", "00", "71"].includes(respCode) &&
            !isDuoUser) {
              history.push("/RegistrationPage");
            } 
            if (["70", "71"].includes(respCode) && isDuoUser) {
              history.push("/Home");
            } 
            if (respCode === "01") {
              history.push("/RegistrationPage1");
            }
            
            
            
            setArrayData(response.data.data.daoInfo[0]);
            localStorage.setItem("clientCodeencr", response.data.clientcodeencr);
            localStorage.setItem("longtoken", response.data.data.daoInfo[0].TOKEN);
            localStorage.setItem("userId", response.data.data.daoInfo[0].USERID);
            localStorage.setItem("AccountNo", response.data.data.daoInfo[0].CARD_ACCOUNTNO);
            localStorage.setItem("CreditCardNo", response.data.data.daoInfo[0].CREDITCARD.PAN);
            localStorage.setItem("CreditLimit", response.data.data.daoInfo[0].CREDIT_LIMIT);
            localStorage.setItem("ActualLimit", response.data.data.daoInfo[0].ACTUAL_LIMIT);
            localStorage.setItem("respcode", response.data.data.daoInfo[0].RESPCODE);
            localStorage.setItem("duoUser", response.data.data.daoInfo[0].duoUser);
            localStorage.setItem("email", response.data.data.daoInfo[0].EMAIL);
            


           
          } else {
            history.push("/Forbidden");

          }

        }).catch(err => {
          // console.error(err);
        });
    })();
  }, [])

  return (
    // <React.Fragment>
    //   <div>
    //     <div>
    //       {(() => {
    //         // const respCode = localStorage.getItem("respcode");
    //         // const isDuoUser = localStorage.getItem("duoUser") === "true";
            
    //         const respCode = arrayData?.RESPCODE ?? '';
    //         const isDuoUser = arrayData?.duoUser;

    //         if (respCode === "74" && isDuoUser) {
    //           return <RegistrationPage2 arrayData={arrayData} />;
    //         }

    //         if (respCode === "74" && !isDuoUser) {
    //           return <RegistrationPage4 arrayData={arrayData} />;
    //         }

    //         if (
    //           ["70", "00", "71"].includes(respCode) &&
    //           !isDuoUser
    //         ) {
    //           return (
    //             <>
    //               <RegistrationPage />
    //             </>
    //           );
    //         }

    //         if (["70", "71"].includes(respCode) && isDuoUser) {
    //           return <Home arrayData={arrayData} />;
    //         }

    //         if (respCode === "01") {
    //           return (
    //             <>
    //               <RegistrationPage1 />
    //             </>
    //           );
    //         }

    //       })()}
    //     </div>
    //   </div>

    // </React.Fragment>
    <div></div>
  )

   


}

  
  export default AppHome;