import React from 'react';



const RegistrationPage = () => {


    return (
        <React.Fragment>
          
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                    </div>
                    <div className='col-md-6'>
                        <div className='register'>
                            <p className='termcondition'>Dear Customer, you are not eligible for the Credit Card product
                             at this time, Kindly call our contact center on<span className='textbold'> 012712003-7 </span>for details</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                </div>
            </div>
        </React.Fragment>
    )

}
export default RegistrationPage;