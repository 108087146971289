import React, { useState, useEffect, useRef } from "react";
import Header from '../Header';
import OtpInput from 'react18-input-otp';
import { useHistory } from 'react-router-dom';
import axiosCall from '../axios/axios';


const ForgotPinSetup = () => {

  const [PIN, setPIN] = useState('');
  const [retypePIN, setRetypePIN] = useState('');
  const [isMasked, setIsMasked] = useState(true);
  const [error, setError] = useState('');
  const [pinMasked, setPinMasked] = useState(true);
  const ref = useRef(null);
  const pinInputRef = useRef(null);
  const retypePinInputRef = useRef(null);
  
  useEffect(() => {
    if (PIN?.length === 4) {
      retypePinInputRef?.current?.focusNextInput();
    }
  }, [PIN]);

  const toggleMask = () => {
    setIsMasked(!isMasked);
  };
  const toggleMaskRetype = () => {
    setPinMasked(!pinMasked);
  };
  let history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (PIN.length !== 4) {
      setError('Please enter 4 digit PIN for AccessDuo app');
    } else if (retypePIN.length !== 4) {
      setError('Please enter 4 digit PIN for AccessDuo app');
    } else if (PIN !== retypePIN) {
      setError('Retype PIN must match with the PIN entered by you');
    } else {
      const body = {
        "ACCOUNTNUMBER": localStorage.getItem("AccountNo"),
        "PIN": PIN
      }
      try {
        const response1 = await axiosCall.POST('/webview/forgotpin', body);
        if (response1.data.status === "200" && response1.data.message === "SUCCESS") {
          localStorage.setItem("registertoken", response1.data.data.token);
          history.push({
            pathname: `/PinSetup`,
          });
        }
      } catch (error) {
        console.log('An error occurred:', error);
      }

    }
  };

  const handleOTPChange = (pin) => {
    setPIN(pin);
    setError('');
  };

  const handleRetypeOTPChange = (pin) => {
    setRetypePIN(pin);
    setError('');
  };

    return (
      <React.Fragment>
        <Header />
        <div className='container'>
          <div className='row'>
            <div className='col-md-3'>
            </div>
            <div className='col-md-6'>
              <div className='register'>
                <p className="setuppin">Please set-up your 4 Digit PIN that would be required for you to access your DUO Card</p>
              </div>
              <div className='formfirst'>
                <form onSubmit={handleSubmit} >
                  <div>
                    <label className="uploadlabel">Enter 4 Digit PIN</label>
                    <div className='pinInput'>
                      <div className='otpfields'>
                        <OtpInput
                          inputStyle='otpinputs'
                          ref={pinInputRef}
                          value={PIN}
                          onChange={handleOTPChange}
                          autoFocus
                          OTPLength={4}
                          isInputSecure={isMasked}
                          isInputNum="number"
                          disabled={false}
                          inputProps={{ inputMode: "numeric" }}
                        />
                     
                      </div>
                      <div onClick={toggleMask} className="toggleeye">
                        {isMasked ? (
                          <i className="fa fa-eye-slash" aria-hidden="true"></i>
                        ) : (
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        )}
                      </div>

                    </div>

                    <label className="uploadlabel1 ">Retype PIN</label>
                    <div className='pinInput'>
                      <div className='otpfields'>
                        <OtpInput
                          inputStyle='otpinputs'
                          ref={retypePinInputRef}
                          value={retypePIN}
                          onChange={handleRetypeOTPChange}
                          numInputs={4}
                          isInputSecure={pinMasked}
                          isInputNum="number"
                          inputProps={{ inputMode: "numeric" }}
                        />
                    
                      </div>
                      <div onClick={toggleMaskRetype} className="toggleeye">
                        {pinMasked ? (
                          <i className="fa fa-eye-slash" aria-hidden="true"></i>
                        ) : (
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        )}
                      </div>
                    </div>
                    {error && <div className='pinerror'>{error}</div>}
                  </div>

                  <div className='register-button'>
                    <input type="submit" className="btn btn-warning nextbuttonpin" value="Next" />

                  </div>
                </form>
              </div>


            </div>
            <div className='col-md-3'>
            </div>
          </div>
        </div >
      </React.Fragment >
    )
  }

export default ForgotPinSetup;