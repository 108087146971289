import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axiosCall from '../axios/axios';
import { Modal, Button } from 'react-bootstrap';
import Loader from './Loader';

const ForPin = () => {

  const [errormsg, setErrormsg] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();

  const addSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const body = {
      "ACCOUNTNUMBER": localStorage.getItem("AccountNo"),
      "TOKEN": "5599",
      "CUSTOMEREMAIL": localStorage.getItem("email"),
      "DLINK_DEVICE": "NO"
    }
    await axiosCall.POST('/registration/mobileotp', body)
      .then((response) => {
        if (response.data.status === "200" && response.data.data.validOtp === true) {
          history.push({
            pathname: '/ResendPin',
          });
          setIsLoading(false);
        } else {
          setModalOpen(true);
          setErrormsg(response.data.data.MESSAGE);
          setIsLoading(false);
        }
      }).catch((error) => {
        console.log(error);
        setIsLoading(false);
        setModalOpen(true);
        setErrormsg("Please try After Sometime");
      });

  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleRedirect = () => {
    handleCloseModal();
 
  };

  return (
    <React.Fragment>

      {isLoading ? <div>
        <Loader className="loadertext" />
        <div>
          {modalOpen && (
            <Modal show={modalOpen} handleClose={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Alert</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className='modaltext'>{errormsg}</p>
                <hr className='hrline' />
              </Modal.Body>

              <Modal.Footer onClick={handleRedirect}>
                <Button variant="secondary" >
                  OK
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
        <button className='forgototppin' onClick={addSubmit}>Forgot PIN? </button>

      </div> :
        <div>
          <div>
            {modalOpen && (
              <Modal show={modalOpen} handleClose={handleCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p className='modaltext'>{errormsg}</p>
                  <hr className='hrline' />
                </Modal.Body>

                <Modal.Footer onClick={handleRedirect}>
                  <Button variant="secondary">
                    OK
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </div>
          <button className='forgototppin' onClick={addSubmit}>Forgot PIN? </button>
        </div>
      }

    </React.Fragment>
  )
}

export default ForPin;