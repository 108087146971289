import React, { useState } from 'react';
import DUOlogo from '../assets/whiteduologo.svg';
import { Navbar } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import duomobile from '../assets/duomobile.svg';
import duocard from '../assets/duocard.svg';
import cardnumber from '../assets/cardnumber-yellow.svg';
import transactionhistory from '../assets/transactionhistory.svg';
import home from '../assets/home-icon.svg';
import axiosCall from '../axios/axios';
import remap from '../assets/remap.svg';
import { Modal, Button } from 'react-bootstrap';
import Loader from './Loader';


import Select from './Select';




const ApplyDuoCard1 = (props) => {
    const { indexArray } = props.location.state;
    const [checked, setChecked] = useState(false);
    const [error, setError] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [isMasked, setIsMasked] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [errormsg, setErrormsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const branchList = indexArray.map((branch) =>
     branch);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        setError('');
    }

    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);
        setIsMasked(!isMasked);
        setError('');
    };
    let history = useHistory();
 
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!checked) {
            setError('Please accept terms and conditions');
        } else if (!selectedOption) {
            setError('Please select an option');
        }
        else {

            const body = {
                "ACCOUNTNUMBER": localStorage.getItem("AccountNo"),
                "BRANCHADDRESS":selectedOption,
                "ACCEPTTERMSANDCONDITION": checked
            }
            await axiosCall.POST('/customer/upgradedualcard', body)
                .then((response) => {
                    if (response.data.status === "200" && response.data.data.RESPCODE === '0') {
                        localStorage.setItem("branch", response.data.data.BRANCH_NAME);
                        setErrormsg(response.data.data.MESSAGE);
                        setModalOpen(true);
                        setIsLoading(false);
                    }
                    else {
                        setModalOpen(true); 
                        setErrormsg(response.data.data.MESSAGE);
                    }

                }).catch((error) => {
                    console.log(error);
                    setModalOpen(true);
                    setErrormsg("Please try After Sometime");
                    setIsLoading(false);
                });
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleRedirect = () => {
        handleCloseModal();
        history.push('/Home');
    };

    return (
        <React.Fragment>
            <Navbar className="navbar navbar-expand-lg navbar-dark bg-dark" bg="dark" expand="lg">
                <div className="navi">

                    <ul className='ulnavicon'>
                        <li className='linavigation imgduologo'> <img className="duosublogotopmain" src={DUOlogo} alt="logo" /></li>
                        <Link to="/Home">  <li className='linavigation'> <img className="duosublogotop " src={home} alt="logo" /></li> </Link>
                            <Link to="/PinVerification?page=VirtualCreditCard">  <li className='linavigation'> <img className="duosublogotop " src={duomobile} alt="logo" /></li> </Link>
                            <Link to="/PinVerification?page=TransactionHistory">   <li className='linavigation'> <img className="duosublogotop " src={transactionhistory} alt="logo" /></li> </Link>
                            <Link to="/PinVerification?page=RepaymentRequest">  <li className='linavigation'> <img className="duosublogotop " src={duocard} alt="logo" /></li></Link>
                            <Link to="/PinVerification?page=Remap">  <li className='linavigation remaplogo'> <img className="duosublogotop " src={remap} alt="logo" /></li></Link>
                            <Link to="/ApplyDuoCard">  <li className='linavigation'> <img className="duosublogotop " src={cardnumber} alt="logo" /></li></Link>
                           
                        </ul>
                </div>
            </Navbar>
        
            <p className='homesummery'>Apply for DUO Card</p>
            <div>
                    {modalOpen && (
                        <Modal show={modalOpen} handleClose={handleCloseModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Alert</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className='modaltext'>{errormsg}</p>
                                <hr className='hrline' />
                            </Modal.Body>
                            <Modal.Footer onClick={handleRedirect}>
                                <Button variant="secondary" >
                                    OK
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    )}
                </div>
                {isLoading ? <div>
                    <Loader className="loadertext" />
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                    </div>
                    <div className='col-md-6'>
                        <p className="applytextduo">Your Branch details</p>
                        <div className='nairaduocardapply'>
                            <p className='applybank'>Access Bank Branch</p>
                            <p className='applybranch'>{localStorage.getItem("branch")}</p>
                        </div>
                        <div className=''>
                            <p className="remapdebitnew2">Submit your request to upgrade to Duo Card Product. You would be notified on this App when your
                                card is ready for pick-up from the above branch</p>
                            <p className="remapdebitnew2">In case you want to pick up your Duo Card from a different branch, please select the option given
                                below and let us know the branch you would like us to deliver this card</p>
                        </div>


                        <form className='formsubmitregistration' onSubmit={handleSubmit}>
                       
                            <div className='applyduotextreg'>
                                <label className="custom-checkbox">
                                    <input type="checkbox" checked={checked} onChange={handleCheckboxChange} />
                                    <span className="checkmark"></span>
                                    <span className="checkbox-label2">
                                        I want to pick-up this card from another branch
                                    </span>
                                </label>
                            </div>
                            {isMasked &&
                            <div className="selectdiv">
                           
                            <div className="select selectfield">
                                <Select
                                    className='applybank'
                                    options={branchList}
                                    selectedOption={selectedOption}
                                    onOptionSelect={handleOptionSelect}
                                    placeholder="Please select your Preferred branch"
                                />
                                <div className="select-arrow"></div>
                            </div>
                            </div>
                             }
                            {error && <div className='pinerror termserror'>{error}</div>}

                            <div className='register-button'>
                                <input type="submit" className="btn btn-warning nextbuttonpin" value="Next" />
                            </div>
                        </form>


                    </div>
                    <div className='col-md-3'>
                    </div>
                </div>
            </div>
            </div> :
             <div className='container'>
             <div className='row'>
                 <div className='col-md-3'>
                 </div>
                 <div className='col-md-6'>
                     <p className="applytextduo">Your Branch details</p>
                     <div className='nairaduocardapply'>
                         <p className='applybank'>Access Bank Branch</p>
                         <p className='applybranch'>{localStorage.getItem("branch")}</p>
                     </div>
                     <div className=''>
                         <p className="remapdebitnew2">Submit your request to upgrade to Duo Card Product. You would be notified on this App when your
                             card is ready for pick-up from the above branch</p>
                         <p className="remapdebitnew2">In case you want to pick up your Duo Card from a different branch, please select the option given
                             below and let us know the branch you would like us to deliver this card</p>
                     </div>


                     <form className='formsubmitregistration' onSubmit={handleSubmit}>
                    
                         <div className='applyduotextreg'>
                             <label className="custom-checkbox">
                                 <input type="checkbox" checked={checked} onChange={handleCheckboxChange} />
                                 <span className="checkmark"></span>
                                 <span className="checkbox-label2">
                                     I want to pick-up this card from another branch
                                 </span>
                             </label>
                         </div>
                         {isMasked &&
                         <div className="selectdiv">
                        
                         <div className="select selectfield">
                             <Select
                                 className='applybank'
                                 options={branchList}
                                 selectedOption={selectedOption}
                                 onOptionSelect={handleOptionSelect}
                                 placeholder="Please select your Preferred branch"
                             />
                             <div className="select-arrow"></div>
                         </div>
                         </div>
                          }
                         {error && <div className='pinerror termserror'>{error}</div>}

                         <div className='register-button'>
                             <input type="submit" className="btn btn-warning nextbuttonpin" value="Next" />
                         </div>
                     </form>


                 </div>
                 <div className='col-md-3'>
                 </div>
             </div>
         </div>
}
        </React.Fragment>
    )

}
export default ApplyDuoCard1;