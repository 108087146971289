import React, { useEffect, useMemo, useState } from 'react';
import Header2 from '../Header2';
import duomobile from '../assets/duomobile-black.svg';
import duocard from '../assets/duocard-black.svg';
import cardnumber from '../assets/cardnumber-black.svg';
import linkimg from '../assets/link.svg';
import video from '../assets/video.svg';
import { Link } from 'react-router-dom';
import transactionhistory from '../assets/transactionhistory-black.svg';
import Charts from './charts';
import Chartsloader from './chartsloader'
import ChartsAB from './chartsAB';
import axiosCall from '../axios/axios';
import Loader from './Loader';
import CardType from './CardType';
import { Modal, Button } from 'react-bootstrap';



const Home = (props) => {
    const [creditStatusData, setCreditStatusData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [chartDataInfo, setChartDataInfo] = useState();
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('default', { month: 'short' });
    const currentYear = currentDate.getFullYear();
    const [modalOpen, setModalOpen] = useState(false);
    const [availableBal, setAvailableBal] = useState();


    useEffect(() => {
        if (props !== undefined && props.location !== undefined && props.location.state !== undefined && props.location.state.isactivity !== undefined && props.location.state.isactivity === true) {
            setModalOpen(true);
        }
    }, [props]);

    useEffect(() => {
        window.addEventListener('beforeunload', handleCloseModal);
        return () => {
            window.removeEventListener('beforeunload', handleCloseModal);
        }
    }, []);

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleRedirect = () => {
        handleCloseModal();

    };

    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            axiosCall.POST('/customer/getduocustomerinfo', { "ACCOUNTNUMBER": localStorage.getItem("AccountNo") }),
            axiosCall.POST('/customer/customercreditstatus', { "ACCOUNTNUMBER": localStorage.getItem("AccountNo") })
        ])
            .then(([response1, response2]) => {
                if (response1.data.status === "200" && response1.data.message === "SUCCESS") {
                    localStorage.setItem("CardIssuer", response1.data.data.CREDITCARD.CARD_ISSUER);
                    localStorage.setItem("CardType", [response1.data.data.CREDITCARD.CARD_TYPE]);
                    localStorage.setItem("AvailableBalance", [response1.data.data.AVAILABLE_BALANCE]);
                    setAvailableBal(response1.data.data);
                }
                if (response2.data.status === "200" && response2.data.message === "SUCCESS") {
                    setCreditStatusData([response2.data.data]);
                    setChartDataInfo(response2.data.data);
                }
                setIsLoading(false);
            }).catch(err => {
                console.error("api throwing error", err);
                setIsLoading(false);
            });

    }, []);

    const checkRespcodeValue = useMemo(() => {
        if (localStorage.getItem("respcode") === "70" || localStorage.getItem("respcode") === "71") {
            return true
        } else {
            return false
        }
    }, [])

    return (

        <React.Fragment>
            <Header2 />
            <div>
                {modalOpen && (

                    <Modal show={modalOpen} handleClose={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Alert</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className='modaltext'>Because of inactivity you have been redirected to home screen</p>
                            <hr className='hrline' />
                        </Modal.Body>

                        <Modal.Footer onClick={handleRedirect}>
                            <Button variant="secondary" >
                                OK
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>

            {isLoading ? <div>

                <div>

                    <p className='homesummery'>Credit Summary</p>
                    <CardType />

                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-3'>
                            </div>
                            <div className='col-md-6'>
                                <div className=''>
                                    <div className='divparent'>

                                        <div className='homecharts'>

                                            <div id="chart">
                                                <Chartsloader />

                                            </div>
                                            <Loader className="loadertext" />
                                        </div>
                                        {
                                            checkRespcodeValue ?
                                                (
                                                    <div className='container activetext'>
                                                        <p className="remapdebit">
                                                            “Green” status means that you have been Activated and therefore you can start making DTS transaction requests now
                                                        </p>
                                                    </div>
                                                )
                                                :
                                                (
                                                    <div className='container activetext'>
                                                        <p className="remapdebit">
                                                            {/* *Orange* status means that your Activation Request is still being attended to. This may take up to 24 hours time before you making transaction requests
                                                        </p> */}
                                                        </p>
                                                    </div>
                                                )

                                        }

                                    </div>
                                </div>

                                <div>
                                    {creditStatusData && <div>
                                        {creditStatusData.map((item, i) => (
                                            <div className="containerBox">
                                                <div className="item ">
                                                    <p className="toptex">Payment Due Date</p>
                                                    {(() => {
                                                        if (item.PAYMENTDUEDATE != null) {
                                                            return (
                                                                <p className="itemend">{item.PAYMENTDUEDATE}</p>
                                                            )
                                                        } else {
                                                            <p className="itemend">15-{currentMonth}-{currentYear}</p>
                                                        }
                                                    })()}
                                                </div>
                                                <div className="item1">
                                                    <p className="toptex">Minimum Payment Due</p>
                                                    <p className="itemend">₦ {Math.abs(item.MINIMUM_REPAYMENT_DUE).toLocaleString(2)}</p>
                                                </div>
                                                <div className="item2">
                                                    <p className="toptex">Card Status</p>
                                                    <p className="itemend1">Active</p>
                                                </div>
                                                <div className="item3">
                                                    <p className="toptex">Payment Status</p>
                                                    <p className="itemend1">Late by  {item.DAYS_OVERDUE} Days</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>}
                                </div>

                                <div className='container homemenues'>
                                    {/* <div className='iconwithtext'>
                                        <div className='iconwithtext1'>
                                            <img className="duologicon" src={video} alt="logo" />
                                        </div>
                                        <div className=''>
                                            <Link to="/ExplainerVideo" className='iconwithtext2'>Explainer Video</Link>
                                        </div>
                                    </div> */}

                                    {/* <hr className="borderhome" /> */}
                                    <div className='iconwithtextop'>
                                        <div className='iconwithtext1'>
                                            <img className="duologicon" src={duomobile} alt="logo" />
                                        </div>
                                        <div className=''>
                                            <Link to="/PinVerification?page=VirtualCreditCard" className='iconwithtext2'>Virtual Credit Card </Link>
                                        </div>
                                    </div>
                                    <hr className="borderhome" />
                                    <div className='iconwithtextop'>
                                        <div className='iconwithtext1'>
                                            <img className="duologicon" src={transactionhistory} alt="logo" />
                                        </div>
                                        <div className=''>
                                            <Link to="/PinVerification?page=TransactionHistory" className='iconwithtext2'> Transaction History </Link>
                                        </div>

                                    </div>
                                    <hr className="borderhome" />
                                    <div className='iconwithtextop'>
                                        <div className='iconwithtext1'>
                                            <img className="duologicon" src={duocard} alt="logo" />
                                        </div>
                                        <div className=''>
                                            <Link to="/PinVerification?page=RepaymentRequest" className='iconwithtext2'> Repayment Request </Link>
                                        </div>
                                    </div>
                                    <hr className="borderhome" />
                                    <div className='iconwithtextop'>
                                        <div className='iconwithtext1'>
                                            <img className="duologicon" src={linkimg} alt="logo" />
                                        </div>
                                        <div>
                                            <Link to="/PinVerification?page=Remap" className='iconwithtext2'>   View / Update Linked Debit Cards (DTS) </Link>
                                        </div>
                                    </div>
                                    {/* <hr className="borderhome" />
                                    <div className='iconwithtextop'>
                                        <div className='iconwithtext1'>
                                            <img className="duologicon" src={cardnumber} alt="logo" />
                                        </div>
                                        <div className=''>
                                            <Link to="/PinVerification?page=ApplyDuoCard" className='iconwithtext2'> Apply for DUO Card </Link>
                                        </div>
                                    </div> */}
                                    {/* <hr className="borderhome" /> */}
                                </div>
                                <div className='col-md-3'>
                                </div>
                            </div>
                            <div className='col-md-3'>
                            </div>
                        </div>
                    </div>
                </div>
            </div> :
                <div>
                    <p className='homesummery'>Credit Summary</p>
                    <CardType />
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-3'>
                            </div>
                            <div className='col-md-6'>
                                <div className=''>
                                    <div className='divparent'>

                                        <div className='homecharts'>
                                            <div id="chart">
                                                {(() => {
                                                    const availableBalance = localStorage.getItem("AvailableBalance");
                                                    console.log("data", availableBalance)
                                                    if (availableBalance === "0" || availableBalance === "0.0") {
                                                        return (
                                                            <ChartsAB data={chartDataInfo} />
                                                        )
                                                    }
                                                    else if (availableBalance !== "0" || availableBalance !== "0.0") {
                                                        return (
                                                            <Charts data={availableBal} />
                                                        )
                                                    }
                                                })()}
                                            </div>
                                            <div className='container'>
                                                <div>
                                                    <div className='chartstatus'>
                                                        <div className='status'>₦ 0.00</div>
                                                        <div className='status1'>Activation Status *
                                                            {(localStorage.getItem("respcode") === "70" || localStorage.getItem("respcode") === "71") ?
                                                                (
                                                                    <div className='statuscirclegreen'>
                                                                    </div>
                                                                )
                                                                : (
                                                                    <div className='statuscircle'>
                                                                    </div>
                                                                )
                                                            }

                                                        </div>
                                                        <div className='status2'>₦  {parseFloat(availableBal?.ACTUAL_LIMIT ?? '0').toLocaleString(2)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {(() => {
                                            if (localStorage.getItem("respcode") === "70" || localStorage.getItem("respcode") === "71") {
                                                return (
                                                    <div className='container activetext'>
                                                        <p className="remapdebit">
                                                            “Green” status means that you have been Activated and therefore you can start making DTS transaction requests now
                                                        </p>
                                                    </div>
                                                )
                                            }
                                            else {
                                                return (
                                                    <div className='container activetext'>
                                                        <p className="remapdebit">
                                                            *Orange* status means that your Activation Request is still being attended to. This may take up to 24 hours time before you making transaction requests</p>
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </div>
                                </div>

                                <div>
                                    {creditStatusData && <div>
                                        {creditStatusData.map((item, i) => (
                                            <div className="containerBox">
                                                <div className="item ">
                                                    <p className="toptex">Payment Due Date</p>
                                                    <p className="itemend">15-{currentMonth}-{currentYear}</p>
                                                    {/* {(() => {
                                                        if (item.PAYMENTDUEDATE != null) {
                                                            return (
                                                                <p className="itemend">{item.PAYMENTDUEDATE}</p>
                                                            )
                                                        } else {
                                                            return (
                                                                <p className="itemend">15-{currentMonth}-{currentYear}</p>
                                                            )
                                                        }
                                                    })()} */}
                                                </div>
                                                <div className="item1">
                                                    <p className="toptex">Minimum Payment Due</p>
                                                    <p className="itemend">₦ {Math.abs(item.MINIMUM_REPAYMENT_DUE).toLocaleString(2)}</p>
                                                </div>
                                                <div className="item2">
                                                    <p className="toptex">Card Status</p>
                                                    {(() => {
                                                        if (item.CARDSTATUS == 1) {
                                                            return (
                                                                <p className="itemend1">Active </p>
                                                            )
                                                        } else {
                                                            return (
                                                                <p className="itemend1">Blocked</p>
                                                            )
                                                        }
                                                    })()}
                                                    
                                                </div>
                                                <div className="item3">
                                                    <p className="toptex">Payment Status</p>
                                                    <p className="itemend1">Late by  {item.DAYS_OVERDUE} Days</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>}
                                </div>

                                <div className='container homemenues'>
                                    {/* <div className='iconwithtext'>
                                        <div className='iconwithtext1'>
                                            <img className="duologicon" src={video} alt="logo" />
                                        </div>
                                        <div className=''>
                                            <Link to="/ExplainerVideo" className='iconwithtext2'>Explainer Video</Link>
                                        </div>
                                    </div> */}

                                    {/* <hr className="borderhome" /> */}
                                    <div className='iconwithtextop'>
                                        <div className='iconwithtext1'>
                                            <img className="duologicon" src={duomobile} alt="logo" />
                                        </div>
                                        <div className=''>
                                            <Link to="/PinVerification?page=VirtualCreditCard" className='iconwithtext2'>Virtual Credit Card </Link>
                                        </div>
                                    </div>
                                    <hr className="borderhome" />
                                    <div className='iconwithtextop'>
                                        <div className='iconwithtext1'>
                                            <img className="duologicon" src={transactionhistory} alt="logo" />
                                        </div>
                                        <div className=''>
                                            <Link to="/PinVerification?page=TransactionHistory" className='iconwithtext2'> Transaction History </Link>
                                        </div>

                                    </div>
                                    <hr className="borderhome" />
                                    <div className='iconwithtextop'>
                                        <div className='iconwithtext1'>
                                            <img className="duologicon" src={duocard} alt="logo" />
                                        </div>
                                        <div className=''>
                                            <Link to="/PinVerification?page=RepaymentRequest" className='iconwithtext2'> Repayment Request </Link>
                                        </div>
                                    </div>
                                    <hr className="borderhome" />
                                    <div className='iconwithtextop'>
                                        <div className='iconwithtext1'>
                                            <img className="duologicon" src={linkimg} alt="logo" />
                                        </div>
                                        <div>
                                            <Link to="/PinVerification?page=Remap" className='iconwithtext2'>   View / Update Linked Debit Cards (DTS) </Link>
                                        </div>
                                    </div>
                                    <br />
                                    {/* <hr className="borderhome" />
                                    <div className='iconwithtextop'>
                                        <div className='iconwithtext1'>
                                            <img className="duologicon" src={cardnumber} alt="logo" />
                                        </div>
                                        <div className=''>
                                            <Link to="/PinVerification?page=ApplyDuoCard" className='iconwithtext2'> Apply for DUO Card </Link>
                                        </div>
                                    </div> */}
                                    {/* <hr className="borderhome" /> */}
                                </div>
                                <div className='col-md-3'>
                                </div>
                            </div>
                            <div className='col-md-3'>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )

}
export default Home;