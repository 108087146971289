import React from 'react';
import Header from '../Header';
import duomobile from '../assets/duomobile-black.svg';
import cardnumber from '../assets/cardnumber-black.svg';



const RegistrationPage2 = (props) => {
  const creditLimit = Number(localStorage.getItem("ActualLimit"));
  const formattedCreditLimit = creditLimit.toLocaleString('en-NG');
  const respCode = localStorage.getItem("respcode");

  return (
    <React.Fragment>
      <Header />
      <div className='container'>
        <div className='row'>
          <div className='col-md-3'>
          </div>
          <div className='col-md-6'>
            <div className='registertop'>
              <div>
                <p className='text-label'>Credit Card Number</p>
                <p className='naira'>{localStorage.getItem("CreditCardNo")}</p>
              </div>
              <div>
                <p className='text-label'>Service Activated</p>
                {(() => {
                  if (localStorage.getItem("respcode") === "71") {
                    return (
                      <p className='naira'>Dual Transaction Service (DTS)</p>
                    );
                  }
                  if (localStorage.getItem("respcode") === "70") {
                    return (
                      <p className='naira'>Dual Chip Processing Card (DCP)</p>
                    );
                  }
                })()}

               
              </div>
              <div>
                <p className='text-label'>Credit Limit</p>
                <p className='naira'>₦ {formattedCreditLimit}</p>
              </div>
              <div>
                <p className='text-label'>Activation Status</p>
                <p className='naira-yellow active3'>Activation in Progress</p>
              </div>
              <p className='duocard'>It may take up to 24 hours before you can start making transaction requests using your DUO card</p>
              <div className='now-below'>
                <p className="small-text">Now you can</p>
              </div>
              <div className='divparent'>
                <div className='ilnetext1'>
                  <img className="duolog" src={duomobile} alt="logo" />
                </div>
                <div className='ilnetext'>
                  Spend online using your Virtual Credit Card on this app
                </div>
              </div>
              <div className='divparent1'>
                <div className='ilnetext1'>
                  <img className="duolog" src={cardnumber} alt="logo" />
                </div>
                <div className='ilnetext'>
                  Spend from your Credit Limit by using your Debit Cards on any POS / ATM in the country by choosing “CREDIT” as transaction mode
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
          </div>
        </div>
      </div>


    </React.Fragment>
  )

}
export default RegistrationPage2;