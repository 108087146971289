import React, {useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import duomobile from '../assets/duomobile.svg';
import duocard from '../assets/duocard.svg';
import cardnumber from '../assets/cardnumber.svg';
import transactionhistory from '../assets/transactionhistory.svg';
import home from '../assets/home-icon.svg';
import DUOlogo from '../assets/whiteduologo.svg';
import axiosCall from '../axios/axios';
import { useForm } from 'react-hook-form';
import remap from '../assets/remap1.svg';
import Loader from './Loader';



const RemapDone = (props) => {

    const {handleSubmit } = useForm();
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);

    const onSubmit = async () => {
        setIsLoading(true);
        const body = {
            "ACCOUNTNUMBER": localStorage.getItem("AccountNo"),
            "PAN_LIST": selectedValues
        }
        await axiosCall.POST('/customer/remapcustomerprofilev', body)
            .then((response) => {
                if (response.data.STATUS === "200" && response.data.MESSAGE === "SUCCESS") {
                    setSuccess(true);
                    setIsLoading(false);
                }
            }).catch((error) => {
                console.log(error);
                setIsLoading(false);
            })
    }
    if (success) {
        return (
            <Redirect
                to={{
                    pathname: "/RemapSuccess",
                    state: { getProfile: selectedValues, creditcared: props.location.state.creditStatusData }
                }}>
            </Redirect>
        )

    } else {

        return (
            <React.Fragment>
                <Navbar className="navbar navbar-expand-lg navbar-dark bg-dark" bg="dark" expand="lg">
                    <div className="navi">
                        <ul className='ulnavicon'>
                            <li className='linavigation imgduologo'> <img className="duosublogotopmain" src={DUOlogo} alt="logo" /></li>
                            <Link to="/Home">  <li className='linavigation'> <img className="duosublogotop " src={home} alt="logo" /></li> </Link>
                            <Link to="/PinVerification?page=VirtualCreditCard">  <li className='linavigation'> <img className="duosublogotop " src={duomobile} alt="logo" /></li> </Link>
                            <Link to="/PinVerification?page=TransactionHistory">   <li className='linavigation'> <img className="duosublogotop " src={transactionhistory} alt="logo" /></li> </Link>
                            <Link to="/PinVerification?page=RepaymentRequest">  <li className='linavigation'> <img className="duosublogotop " src={duocard} alt="logo" /></li></Link>
                            <Link to="/PinVerification?page=Remap">  <li className='linavigation remaplogo'> <img className="duosublogotop " src={remap} alt="logo" /></li></Link>
                            {/* <Link to="/PinVerification?page=ApplyDuoCard">  <li className='linavigation'> <img className="duosublogotop " src={cardnumber} alt="logo" /></li></Link> */}

                        </ul>
                    </div>
                </Navbar>
                <p className='homesummery'>View / Update Linked Debit Cards (DTS)</p>
                {isLoading ? <div>

                    <div className='container remapview'>
                        <div className='row'>
                            <div className='col-md-3'>
                            </div>
                            <div className='col-md-6'>
                                <div className="container-listitem remap-text">
                                    <div className="listitemremap">
                                        <p className='remaplist'> Virtual Credit Card Number</p>
                                    </div>

                                    <div className="listitemremap1">
                                        <p className='remaplist'>{props.location.state.creditStatusData[0].CREDITCARD.PAN}</p>
                                    </div>
                                </div>

                                <div className="container-listitem Remapdiv ">
                                    <div className="listitemremap">
                                        <p className='remaplist'> Bank Account Number</p>
                                    </div>

                                    <div className="listitemremap1">
                                        <p className='remaplist'>  {"XXXXXXX" + props.location.state.creditStatusData[0].ACCOUNT.slice(-4).repeat(1)}</p>
                                    </div>
                                </div>
                                <p className="remapdebitnew2">Following Debit Cards attached to your bank account number are linked to this virtual Credit Card.</p>


                                <ul className='listremap'>
                                    {props.location.state.getProfile.map((item) => (
                                        <li className="list-group-item" key={item.MASKEDPAN}>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id={item.MASKEDPAN}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setSelectedValues([...selectedValues, item.MASKEDPAN]);
                                                        } else {
                                                            setSelectedValues(selectedValues.filter((val) => val !== item.MASKEDPAN));
                                                        }
                                                    }}
                                                />
                                                <label className="custom-control-label" htmlFor={item.MASKEDPAN}>
                                                    {item.MASKEDPAN}
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                                <Loader />
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='register-button'>
                                        <input type="submit" className="btn btn-warning nextbuttonpinRey" value="Submit" />

                                    </div>
                                </form>
                            </div>
                            <div className='col-md-3'>
                            </div>
                        </div>
                    </div>
                </div> :
                    <div className='container remapview'>
                        <div className='row'>
                            <div className='col-md-3'>
                            </div>
                            <div className='col-md-6'>
                                <div className="container-listitem remap-text">
                                    <div className="listitemremap">
                                        <p className='remaplist'> Virtual Credit Card Number</p>
                                    </div>

                                    <div className="listitemremap1">
                                        <p className='remaplist'>{props.location.state.creditStatusData[0].CREDITCARD.PAN}</p>
                                    </div>
                                </div>

                                <div className="container-listitem Remapdiv ">
                                    <div className="listitemremap">
                                        <p className='remaplist'> Bank Account Number</p>
                                    </div>

                                    <div className="listitemremap1">
                                        <p className='remaplist'> {"XXXXXXX" + props.location.state.creditStatusData[0].ACCOUNT.slice(-4).repeat(1)}</p>
                                    </div>
                                </div>
                                <p className="remapdebitnew2">Following Debit Cards attached to your bank account number are linked to this virtual Credit Card.</p>

                                <ul className='listremap'>
                                    {props.location.state.getProfile.map((item) => (
                                        <li className="list-group-item" key={item.MASKEDPAN}>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id={item.MASKEDPAN}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setSelectedValues([...selectedValues, item.MASKEDPAN]);
                                                        } else {
                                                            setSelectedValues(selectedValues.filter((val) => val !== item.MASKEDPAN));
                                                        }
                                                    }}
                                                />
                                                <label className="custom-control-label" htmlFor={item.MASKEDPAN}>
                                                    {item.MASKEDPAN}
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='register-button'>
                                        <input type="submit" className="btn btn-warning nextbuttonpinRey" value="Submit" />

                                    </div>
                                </form>
                            </div>
                            <div className='col-md-3'>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}
export default RemapDone;