import ReactPlayer from 'react-player';
import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import duomobile from '../assets/duomobile.svg';
import duocard from '../assets/duocard.svg';
import cardnumber from '../assets/cardnumber.svg';
import transactionhistory from '../assets/transactionhistory.svg';
import home from '../assets/home-icon.svg';
import DUOlogo from '../assets/whiteduologo.svg';
import remap from '../assets/remap.svg';

function VideoPlayer() {
 
  
  return (
    <React.Fragment>
    <Navbar className="navbar navbar-expand-lg navbar-dark bg-dark" bg="dark" expand="lg">
                <div className="navi">
                <ul className='ulnavicon'>
                        <li className='linavigation imgduologo'> <img className="duosublogotopmain" src={DUOlogo} alt="logo" /></li>
                        <Link to="/Home">  <li className='linavigation'> <img className="duosublogotop " src={home} alt="logo" /></li> </Link>
                        <Link to="/VirtualCreditCard">  <li className='linavigation'> <img className="duosublogotop " src={duomobile} alt="logo" /></li> </Link>
                         <Link to="/PinVerification?page=TransactionHistory">   <li className='linavigation'> <img className="duosublogotop " src={transactionhistory} alt="logo" /></li> </Link>
                         <Link to="/PinVerification?page=RepaymentRequest">  <li className='linavigation'> <img className="duosublogotop " src={duocard} alt="logo" /></li></Link>                       
                         <Link to="/PinVerification?page=Remap">  <li className='linavigation remaplogo'> <img className="duosublogotop " src={remap} alt="logo" /></li></Link>
                         {/* <Link to="/PinVerification?page=ApplyDuoCard">  <li className='linavigation'> <img className="duosublogotop " src={cardnumber} alt="logo" /></li></Link>
                        */}
                        </ul>
                </div>
            </Navbar>
    <div className='container'>
      <ReactPlayer
       url="https://vimeo.com/645117255"
       width="100%"
       height="360px"
       controls
       
       />
     
    </div>
    </React.Fragment>
  );
}

export default VideoPlayer;