import React, { useState, useEffect } from 'react';
import DUOlogo from '../assets/whiteduologo.svg';
import { useLocation } from 'react-router-dom';
import OtpInput from 'react18-input-otp';
import { useHistory } from 'react-router-dom';
import axiosCall from '../axios/axios';
import ForgotPin from './ForgotPin';
import { Modal, Button } from 'react-bootstrap';
import Loader from './Loader';


const PinVerification = (props) => {
    const location = useLocation();
    const myValue = new URLSearchParams(location.search).get('page');
    const [PIN, setPIN] = useState('');
    const [isMasked, setIsMasked] = useState(true);
    const [error, setError] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [errormsg, setErrormsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [amountValue, setAmountValue] = useState();

    useEffect(() => {
        if (props !== undefined && props.location !== undefined && props.location.state !== undefined && props.location.state.formattedValue !== undefined ) {
            setAmountValue(props.location.state.formattedValue);
        }
    }, [props]); 
    const toggleMask = () => {
        setIsMasked(!isMasked);
    };
    let history = useHistory();

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        if (PIN.length !== 4 || isNaN(PIN)) {
            setError('Please enter 4 digit PIN for AccessDuo app');
        } else {
            const body = {
                "ACCOUNTNUMBER": localStorage.getItem("AccountNo"),
                "PIN": PIN
            }
            axiosCall.POST('/customer/mobilepinverify', body)
                .then((response) => {
                    if (response.data.status === "200" && response.data.data.VALID_PIN === true) {
                        if (myValue !== '') {
                            history.push({
                                pathname: `/${myValue}`,
                            });
                        }
                        if (myValue == null) {
                            history.push({
                                pathname: '/Success',
                                state: {amountValue}
                            });
                    }
                }
                   
                     else {
                        setErrormsg(response.data.data.MESSAGE);
                        setModalOpen(true);
                        setIsLoading(false);
                    }
                }).catch(err => {
                    console.log(err)
                    setErrormsg("Please try After Sometime");
                    setModalOpen(true);
                    setIsLoading(false);
                });
        }
    };
    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleRedirect = () => {
        handleCloseModal();
    };

    const handleChange = (enteredOtp) => {
        setPIN(enteredOtp); 
        setError('');

    };


    return (
        <React.Fragment>
            <div>
                {modalOpen && (
                    <Modal show={modalOpen} handleClose={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Alert</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className='modaltext'>{errormsg}</p>
                            <hr className='hrline' />
                        </Modal.Body>

                        <Modal.Footer onClick={handleRedirect}>
                            <Button variant="secondary" >
                                OK
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
            <div>
                <div className='HeaderBgverify'>
                    <div className="duoheaderveryfi">
                        <img className="duolog" src={DUOlogo} alt="logo" />
                    </div>
                    <p className='pinverifyheader'> PIN Verfication</p>
                </div>

            </div>
            {isLoading ? <div>
                <Loader />
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3'>
                        </div>
                        <div className='col-md-6'>

                            <div className='formfirst pinform'>
                                <form onSubmit={handleSubmit}>
                                    <div >
                                        <label className="uploadlabel">Enter your Access Duo Pin</label>

                                        <div className='pinInput'>
                                            <div className='otpfields'>
                                                <OtpInput
                                                    inputStyle='otpinputs'
                                                    value={PIN}
                                                    onChange={handleChange}
                                                    numInputs={4}
                                                    isInputSecure={isMasked}
                                                    isInputNum="number"
                                                    inputProps={{ inputMode: "numeric" }}
                                                />
                                            </div>
                                            <div onClick={toggleMask} className="toggleeye">
                                                {isMasked ? (
                                                    <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                ) : (
                                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                                )}
                                            </div>

                                        </div>
                                        {error && <div className='pinerror'>{error}</div>}
                                    </div>

                                    {/* <p className='errormsgpin1'>{errormsg}</p> */}
                                    <div className='forgototp'>
                                        <ForgotPin />
                                    </div>

                                    <div className='register-button pinotp'>
                                        <input type="submit" className="btn btn-warning nextbuttonpin" value="Next" />
                                    </div>
                                </form>
                            </div>

                        </div>
                        <div className='col-md-3'>
                        </div>
                    </div>
                </div>
            </div> :
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3'>
                        </div>
                        <div className='col-md-6'>

                            <div className='formfirst pinform'>
                                <form onSubmit={handleSubmit}>
                                    <div >
                                        <label className="uploadlabel">Enter your Access Duo Pin</label>

                                        <div className='pinInput'>
                                            <div className='otpfields'>
                                                <OtpInput
                                                    inputStyle='otpinputs'
                                                    value={PIN}
                                                    onChange={handleChange}
                                                    numInputs={4}
                                                    isInputSecure={isMasked}
                                                    isInputNum="number"
                                                    inputProps={{ inputMode: "numeric" }}
                                                />
                                            </div>
                                            <div onClick={toggleMask} className="toggleeye">
                                                {isMasked ? (
                                                    <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                ) : (
                                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                                )}
                                            </div>

                                        </div>
                                        {error && <div className='pinerror'>{error}</div>}
                                    </div>


                                    {/* <p className='errormsgpin1'>{errormsg}</p> */}
                                    <div className='forgototp'>
                                        <ForgotPin />
                                    </div>

                                    <div className='register-button pinotp'>
                                        <input type="submit" className="btn btn-warning nextbuttonpin" value="Next" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='col-md-3'>
                        </div>
                    </div>
                </div>
            }

        </React.Fragment>
    )
}
// }
export default PinVerification;